export default {
  "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
  "deny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigt"])},
  "rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
  "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen"])},
  "reopen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wieder öffnen"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
  "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fehler"])},
  "fetching": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laden..."])},
  "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
  "pdfExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Export"])},
  "resetReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht zurücksetzen"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahr"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Info"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])},
  "canceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storniert"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
  "project": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt"])},
  "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte"])},
  "myProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Projekte"])},
  "lead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead"])},
  "leads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads"])},
  "myLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Leads"])},
  "companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "closedLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossene Leads"])},
  "closedProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossene Projekte"])},
  "query builder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
  "project dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Dashboard"])},
  "company dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen Dashboard"])},
  "person dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen Dashboard"])},
  "contact dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Dashboard"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsoptionen"])},
  "termsAndPrivacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Einloggen akzeptieren Sie die Bedingungen und den Hinweis zum Datenschutz."])},
  "learnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "contactUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedingungen"])},
  "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
  "googleAuthenticatorText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Einrichtung der Multi-Faktor-Authentifizierung auf Ihrem mobilen Endgerät, müssen Sie dort ein kompatibles Programm, wie z.B. den Google Authenticator (https://googleauthenticator.net/) im Play oder Apple Store herunterladen."])},
  "scanQRCodeInAuthenticator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um Ihr Konto hinzuzufügen, öffnen Sie den installierten Authenticator und wählen Sie z.B. beim Google Authenticator: QR-Code scannen und halten Sie Ihre Kamera über den unten angezeigten QR-Code."])},
  "manuelConnectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollte es zu Problemen kommen und der QR-Code nicht angenommen werden, können Sie folgenden Einrichtungsschlüssel auch manuell eingeben: "])},
  "loginProcessWarningForMfaCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie Ihr Konto im Authenticator erfolgreich hinzugefügt haben, bestätigen Sie dieses Popup oben rechts, und geben Sie im darauffolgenden Fenster einen vom Authenticator angezeigten Code zur Überprüfung ein."])},
  "warningDifferentCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte beachten Sie, dass nachdem Ihr Account erfolgreich verknüpft wurde ein weiterer Code vom Authenticator im darauffolgenden Login-Fenster benötigt wird. Dieser darf nicht der gleiche Code sein, den Sie im vorherigen Schritt verwendet haben."])},
  "copyingKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schlüssel kopieren"])},
  "codeDoesntMatch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Code stimmt nicht überein."])},
  "enterMfaToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie einen MFA-Token zur Anmeldung ein."])},
  "passwordChanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Passwort wurde geändert!"])},
  "newPasswordRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neues Passwort ist erforderlich, bitte geben Sie Ihr neues Passwort ein"])},
  "verifyMfaToken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizieren Sie Ihr MFA-Gerät, indem Sie den angegebenen Code eingeben"])},
  "resetPasswordText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Benutzernamen ein, um den Prozess der Passwortrücksetzung zu starten. Wenn dies Ihre erste Anmeldung ist, finden Sie Ihren Benutzernamen in der Willkommens-E-Mail"])},
  "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifizierungscode"])},
  "enterVerificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie den Verifizierungscode ein, der an Ihre E-Mail-Adresse gesendet wurde: "])},
  "strongNewPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein neues, sicheres Passwort ein"])},
  "aboutUsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist die Über uns Seite"])},
  "openPopup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popup öffnen"])},
  "openSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen seit "])},
  "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tagen"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlicher"])},
  "equipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipments"])},
  "hasEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat Equipments"])},
  "hasNoEquipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hat keine Equipments"])},
  "existingCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandskunde"])},
  "interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessent"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung"])},
  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersteller"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt"])},
  "createdOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])},
  "openReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung öffnen"])},
  "prio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorität"])},
  "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
  "correspondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beiträge"])},
  "offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot"])},
  "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebote"])},
  "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suche"])},
  "searchPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personensuche"])},
  "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmensuche"])},
  "members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder"])},
  "lastVisitedPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt angesehene Person"])},
  "leadHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead-Verlauf"])},
  "projectHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt-Verlauf"])},
  "leadConversion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead-Konvertierung"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festnetz"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
  "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstelldatum"])},
  "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
  "placeholder.domainId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain Id eingeben"])},
  "placeholder.username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername eingeben"])},
  "placeholder.password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort eingeben"])},
  "placeholder.search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchen..."])},
  "placeholder.select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen..."])},
  "placeholder.enter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingeben..."])},
  "placeholder.responsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlichen wählen"])},
  "placeholder.company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma wählen"])},
  "placeholder.equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipment wählen"])},
  "placeholder.equipments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipments wählen"])},
  "placeholder.selectLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache wählen"])},
  "placeholder.reminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung wählen"])},
  "placeholder.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel eingeben"])},
  "placeholder.description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Beschreibung ein"])},
  "placeholder.sortBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sortiere nach"])},
  "placeholder.phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
  "placeholder.interested": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interessent eingeben"])},
  "placeholder.prio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prio wählen"])},
  "placeholder.visibleTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichtbar für"])},
  "placeholder.selectMembers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitglieder wählen"])},
  "placeholder.budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget eingeben"])},
  "placeholder.offerValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotswert eingeben"])},
  "placeholder.offerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotstyp auswählen"])},
  "placeholder.bonusOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionswert eingeben"])},
  "placeholder.probability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgswahrscheinlichkeit in % auswählen"])},
  "placeholder.step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt auswählen"])},
  "placeholder.customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde auswählen"])},
  "placeholder.name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namen eingeben"])},
  "placeholder.email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email eingeben"])},
  "placeholder.location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort auswählen"])},
  "placeholder.address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse eingeben"])},
  "placeholder.country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land eingeben"])},
  "placeholder.phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer eingeben"])},
  "placeholder.website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite eingeben"])},
  "placeholder.category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie eingeben"])},
  "placeholder.responsibleInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verantwortlichen eingeben"])},
  "placeholder.taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt. ID-Nr. eingeben"])},
  "placeholder.person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person auswählen"])},
  "placeholder.searchPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person suchen"])},
  "placeholder.searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma suchen"])},
  "placeholder.searchClosedLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead suchen"])},
  "placeholder.searchClosedProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt suchen"])},
  "placeholder.selectContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt wählen"])},
  "placeholder.phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer eingeben"])},
  "placeholder.landline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festnetz eingeben"])},
  "placeholder.contactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktnamen eingeben"])},
  "placeholder.enterComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text eingeben..."])},
  "placeholder.selectProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wählen"])},
  "placeholder.selectTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wählen"])},
  "placeholder.displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigenamen eingeben"])},
  "placeholder.personNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personennummer eingeben"])},
  "placeholder.position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position eingeben"])},
  "placeholder.salutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede auswählen"])},
  "placeholder.firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname eingeben"])},
  "placeholder.lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname eingeben"])},
  "placeholder.mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer eingeben"])},
  "placeholder.department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung eingeben"])},
  "placeholder.building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude eingeben"])},
  "placeholder.birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag eingeben"])},
  "placeholder.notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen eingeben"])},
  "placeholder.commissionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionsnummer eingeben"])},
  "placeholder.commissionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionswert eingeben"])},
  "placeholder.orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnummer (Hauptauftrag) eingeben"])},
  "placeholder.orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellwert eingeben"])},
  "placeholder.referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer eingeben"])},
  "placeholder.supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferwerk auswählen"])},
  "placeholder.offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot auswählen"])},
  "placeholder.type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ auswählen"])},
  "placeholder.assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnung auswählen"])},
  "placeholder.amountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezahlter Betrag (Euro) eingeben"])},
  "placeholder.competitionModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettbewerbsmodel eingeben"])},
  "placeholder.machineModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinenmodel eingeben"])},
  "placeholder.discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachlass in % eingeben"])},
  "placeholder.offerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsnummer eingeben"])},
  "placeholder.machineNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinennummer eingeben"])},
  "placeholder.theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema auswählen"])},
  "swal.deleteLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead löschen"])},
  "swal.deleteLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Lead löschen ?"])},
  "swal.convertLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead konvertieren"])},
  "swal.convertLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Lead zu einem Projekt konvertieren ?"])},
  "swal.closeLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead absagen"])},
  "swal.closeLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Lead absagen ?"])},
  "swal.deleteReminderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung löschen"])},
  "swal.deleteReminderText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Erinnerung löschen ?"])},
  "swal.leadCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde erstellt"])},
  "swal.leadDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde gelöscht"])},
  "swal.leadConverted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde konvertiert"])},
  "swal.leadClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde geschlossen"])},
  "swal.reopenLeadHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wieder öffnen"])},
  "swal.reopenLeadText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Lead wieder öffnen ?"])},
  "swal.leadReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde wieder geöffnet"])},
  "swal.remindersEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerungen wurden editiert"])},
  "swal.reminderDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung wurde gelöscht"])},
  "swal.rejectedText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben abgelehnt"])},
  "swal.projectCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde erstellt"])},
  "swal.deleteProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt löschen"])},
  "swal.deleteProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Projekt & alles dazugehörige löschen ? (Angebote, Berichte etc.)"])},
  "swal.projectDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde gelöscht"])},
  "swal.closeProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt schließen"])},
  "swal.closeProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Projekt schließen ?"])},
  "swal.projectClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde geschlossen"])},
  "swal.reopenProjectHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wieder öffnen"])},
  "swal.reopenProjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Projekt wieder öffnen ?"])},
  "swal.projectReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde wieder geöffnet"])},
  "swal.projectEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt wurde editiert"])},
  "swal.noMembersSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie müssen min. 1 Mitglied auswählen!"])},
  "swal.deleteTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe löschen"])},
  "swal.deleteTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Aufgabe löschen ?"])},
  "swal.taskDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wurde gelöscht"])},
  "swal.filteredPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beiträge und Aufgaben werden gefiltert nach: "])},
  "swal.closeTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe schließen"])},
  "swal.closeTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Aufgabe schließen ?"])},
  "swal.taskClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe geschlossen"])},
  "swal.reopenTaskHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wieder öffnen"])},
  "swal.reopenTaskText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Aufgabe wieder öffnen ?"])},
  "swal.taskReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wurde wieder geöffnet"])},
  "swal.closeOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot schließen"])},
  "swal.closeOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Angebot schließen ?"])},
  "swal.offerClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot geschlossen"])},
  "swal.offerCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot erstellt"])},
  "swal.offerEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot editiert"])},
  "swal.deleteOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot löschen"])},
  "swal.deleteOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Angebot löschen ?"])},
  "swal.offerDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot gelöscht"])},
  "swal.reopenOfferHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot wieder öffnen"])},
  "swal.reopenOfferText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie dieses Angebot wieder öffnen ?"])},
  "swal.offerReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot wurde wieder geöffnet"])},
  "swal.provideLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre Anmeldedaten an."])},
  "swal.copiedMFAKey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MFA-Schlüssel erfolgreich kopiert"])},
  "swal.logoutHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
  "swal.logoutText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie sich ausloggen ?"])},
  "swal.changingLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache wird geändert..."])},
  "swal.noSalesLicense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie besitzen keine Sales Lizenz."])},
  "swal.correspondenceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag wurde erstellt"])},
  "swal.correspondenceEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag wurde editiert"])},
  "swal.fetchingData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten werden geholt"])},
  "swal.dataUpToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten erfolgreich geholt"])},
  "swal.reportSaved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht wurde gespeichert"])},
  "swal.resetReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht zurücksetzen"])},
  "swal.resetReportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie alle Veränderungen rückgängig machen? (Sollten Sie Ihren Bericht bereits gespeichert haben, werden diese Veränderungen nicht zurückgesetzt!)"])},
  "swal.reportReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht wurde zurückgesetzt."])},
  "swal.taskAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wurde hinzugefügt"])},
  "swal.taskEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe wurde editiert"])},
  "swal.takeOverLeadTagsHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Tags übernehmen"])},
  "swal.takeOverLeadTagsText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie die Lead-Klassifizierung und Tags in das Projekt übernehmen?"])},
  "swal.leadEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead wurde editiert"])},
  "swal.saleCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf wurde erstellt"])},
  "swal.saleEdited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf wurde editiert"])},
  "swal.cancelSaleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf stornieren"])},
  "swal.cancelSaleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Verkauf stornieren ?"])},
  "swal.saleCanceled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf storniert"])},
  "swal.deleteSaleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf löschen"])},
  "swal.deleteSaleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Verkauf löschen ?"])},
  "swal.saleDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf gelöscht"])},
  "swal.contactCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt wurde angelegt"])},
  "swal.deleteCorrespondenceHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag löschen"])},
  "swal.deleteCorrespondenceText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Beitrag löschen ?"])},
  "swal.correspondenceDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag wurde gelöscht"])},
  "swal.deleteFileHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei löschen"])},
  "swal.deleteFileText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diese Datei löschen ?"])},
  "swal.fileDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wurde gelöscht"])},
  "swal.submitWithoutUploadingHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien nicht hochgeladen"])},
  "swal.submitWithoutUploadingText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Dateien wurden ausgewählt aber noch nicht hochgeladen, wollen Sie trotzdem fortfahren ?"])},
  "swal.deleteReportHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht löschen"])},
  "swal.deleteReportText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Bericht löschen ?"])},
  "swal.reportDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht wurde gelöscht"])},
  "swal.reportCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht wurde erstellt"])},
  "swal.deleteReportWarningHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dazugehörigen Bericht löschen ?"])},
  "swal.deleteReportWarningText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch das Löschen diesen Beitrags, wird der dazugehörige Bericht ebenfalls gelöscht. Möchten Sie diesen Beitrag trotzdem löschen ?"])},
  "swal.taskCommentAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar wurde hinzugefügt"])},
  "swal.leadContactIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der letzte Kontakt erfolgte im Lead: "])},
  "swal.noContactInitiated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es erfolgte noch kein Kontakt"])},
  "swal.errorWhenRoutingToUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Öffnen der Person ist ein Fehler aufgetreten. Bitte nutzen Sie die Suchleiste."])},
  "swal.allDataReceived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Daten sind bereits vorhanden."])},
  "swal.reopenSaleHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf wieder öffnen"])},
  "swal.reopenSaleText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie diesen Verkauf wieder öffnen ?"])},
  "swal.saleReopened": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf wurde wieder geöffnet"])},
  "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
  "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen"])},
  "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achtung"])},
  "noChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Veränderungen vorhanden"])},
  "favorites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favoriten"])},
  "sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauft"])},
  "replaced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ersetzt"])},
  "lost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verloren"])},
  "delayed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschoben"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storno"])},
  "noRealization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Realisierung"])},
  "postponed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verschoben"])},
  "stopped": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestoppt"])},
  "newCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Beitrag"])},
  "newLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Lead"])},
  "editLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads editieren"])},
  "newProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Projekt"])},
  "editProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt editieren"])},
  "closeProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt schließen"])},
  "addTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe erstellen"])},
  "editTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe editieren"])},
  "viewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe ansehen"])},
  "createLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead anlegen"])},
  "createProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt anlegen"])},
  "createCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag anlegen"])},
  "editCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag editieren"])},
  "selectCorrespondenceType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag Typ auswählen"])},
  "firstReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erinnerung"])},
  "leadReminder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Erinnerungen"])},
  "editCustomer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunde editieren"])},
  "cardView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kachelansicht"])},
  "tableView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabellenansicht"])},
  "leadsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads erstellt"])},
  "leadsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leads geschlossen"])},
  "projectsCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte erstellt"])},
  "projectsClosed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekte geschlossen"])},
  "offersValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots Wert"])},
  "newContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Neuer Kontakt"])},
  "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtern"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "filesize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateigröße"])},
  "lastModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt bearbeitet"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "expiresAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fällig bis"])},
  "expiresIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fällig in"])},
  "expiredSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fällig seit"])},
  "displayName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeigename"])},
  "task": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgabe"])},
  "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben"])},
  "taskCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben Anzahl"])},
  "changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderungslog"])},
  "projectCorrespondences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beiträge zum Projekt"])},
  "createNewCorrespondence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Beitrag erstellen"])},
  "createNewTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Aufgabe erstellen"])},
  "createNewOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Angebot erstellen"])},
  "uploadImage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild hochladen"])},
  "uploadFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
  "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Person"])},
  "persons": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "employee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "employeeCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter Anzahl"])},
  "correspondenceCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beiträge Anzahl"])},
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte"])},
  "contactCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt Anzahl"])},
  "followingChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat folgende Veränderungen durchgeführt:"])},
  "uploadedFollowingFiles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hat folgende Dateien hochgeladen:"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht"])},
  "reports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichte"])},
  "visitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuchsbericht"])},
  "visitReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besuchsberichte"])},
  "phoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonbericht"])},
  "phoneReports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonberichte"])},
  "createReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bericht erstellen"])},
  "createVisitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Besuchsbericht erstellen"])},
  "newVisitReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Besuchsbericht"])},
  "createPhoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Telefonbericht erstellen"])},
  "newPhoneReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Telefonbericht"])},
  "reportName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtsname"])},
  "reportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichtstyp"])},
  "reportNameInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Name des Berichts darf keine Punkte beinhalten! Z.B. Bericht.IhrName. Verwenden Sie lieber das '_' Zeichen, z.B. Bericht_IhrName"])},
  "createContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Kontakt erstellen"])},
  "selectReportType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berichttyp auswählen"])},
  "newReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Neuer Bericht"])},
  "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liefertermin"])},
  "offerDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots Datum"])},
  "budget": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget in €"])},
  "offerValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotswert in €"])},
  "offerValues": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebots-Werte"])},
  "offerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotstyp"])},
  "bonusOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option in €"])},
  "probability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgswahrscheinlichkeit in %"])},
  "step": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schritt"])},
  "projectTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Titel"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma"])},
  "companyNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmennummer"])},
  "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
  "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
  "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postleitzahl"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standorte"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
  "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefonnummer"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handynummer"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webseite"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
  "taxId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["USt. ID-Nr."])},
  "leadCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lead Anzahl"])},
  "projectCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt Anzahl"])},
  "offerCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebot Anzahl"])},
  "saleCount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf Anzahl"])},
  "createNewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Benachrichtigung erstellen"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benachrichtigungen"])},
  "noNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Benachrichtigungen vorhanden"])},
  "markAllAsRead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle als gelesen markieren"])},
  "people": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personen"])},
  "department": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abteilung"])},
  "companyOpenLeadOrProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen mit off. Leads o. Projekte"])},
  "projectDoesntExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieses Projekt wurde gelöscht und existiert nicht mehr!"])},
  "changePhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wechseln"])},
  "projectPhases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt-Phasen"])},
  "requirementPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektanfrage"])},
  "appointmentPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planungsphase"])},
  "offerPhaseWithoutSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsphase ohne Lastenheft"])},
  "offerPhaseWithSpecifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsphase mit Lastenheft"])},
  "negotiationPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verhandlungsphase"])},
  "projectDeliveryPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektübergabe"])},
  "postProcessingPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachbereitung"])},
  "container": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Container"])},
  "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klassifizierung"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "taskTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben Tags"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
  "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
  "startQuery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abfrage starten"])},
  "taskComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufgaben Kommentare"])},
  "prime.config.startsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beginnt mit"])},
  "prime.config.endsWith": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endet mit"])},
  "prime.config.contains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthält"])},
  "prime.config.notContains": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enthält nicht"])},
  "prime.config.equals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmt überein"])},
  "prime.config.notEquals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stimmt nicht überein"])},
  "prime.config.noFilter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein Filter"])},
  "prime.config.clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leeren"])},
  "prime.config.today": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heute"])},
  "prime.config.accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
  "prime.config.reject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
  "prime.config.upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochladen"])},
  "prime.config.choose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auswählen"])},
  "prime.config.cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
  "prime.config.matchAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Übereinstimmungen"])},
  "prime.config.matchAny": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebige Übereinstimmung"])},
  "prime.config.any": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beliebig"])},
  "prime.config.addRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondition hinzufügen"])},
  "prime.config.removeRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kondition entfernen"])},
  "authenticationOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentifizierungsoptionen"])},
  "authenticationHintPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort erforderlich. Wählen Sie Authentifizierungsoptionen um ein neues Passwort zu vergeben"])},
  "authenticationHintMFA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MFA Einrichtung erforderlich. Wählen Sie Authentifizierungsoptionen um die Einrichtung vorzunehmen."])},
  "optionalParams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optionale Parameter"])},
  "personNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personennummer"])},
  "position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])},
  "salutations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anrede"])},
  "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
  "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
  "building": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäude"])},
  "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtstag"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notizen"])},
  "mr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herr"])},
  "mrs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frau"])},
  "diverse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diverse"])},
  "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
  "createNewSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Verkauf erstellen"])},
  "commissionNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionsnummer"])},
  "commissionValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provisionswert"])},
  "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellnummer (Hauptauftrag)"])},
  "orderValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestellwert"])},
  "salesDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkaufsdatum"])},
  "referenceNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsnummer"])},
  "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieferwerk"])},
  "createPDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF erstellen"])},
  "amountPaid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezahlter Betrag (Euro)"])},
  "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahldatum"])},
  "missingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstehender Betrag (Euro)"])},
  "paymentDeadline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsfrist"])},
  "competitionModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wettbewerbsmodell"])},
  "machineModel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinenmodell"])},
  "payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungen"])},
  "showPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Zahlungen anzeigen"])},
  "errorKeysNeeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folgende Pflichtfelder wurden nicht ausgefüllt: "])},
  "assignment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuordnung"])},
  "retired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausgeschieden"])},
  "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wurden hochgeladen"])},
  "lastContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzter Kontakt"])},
  "manufacturer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hersteller"])},
  "selectSomething": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie ein Lead, eine Aufgabe, ein Angebot, ein Verkauf oder einen Beitrag aus, um diese hier anzeigen zu lassen!"])},
  "correspondenceWithoutContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beitrag ohne ausgewählten Kontakt"])},
  "loadMoreData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr Daten laden"])},
  "loadMoreClosedData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr geschlossene Daten laden"])},
  "earliestLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt erhaltenes Lead:"])},
  "earliestProject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuletzt erhaltenes Projekt:"])},
  "gotAllLeads": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Leads erhalten"])},
  "gotAllProjects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Projekte erhalten"])},
  "showDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschreibung anzeigen"])},
  "dataLoadedTill": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten geladen bis: "])},
  "discountPercentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachlass in %"])},
  "offerNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angebotsnummer"])},
  "timespan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitraum"])},
  "loadNext6Months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weitere 6 Monate laden"])},
  "loadContacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakte laden"])},
  "maximize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximieren"])},
  "minimize": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimieren"])},
  "machineNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maschinennummer"])},
  "markedFieldsAreMandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Felder markiert mit einem '*' sind Pflichtfelder"])},
  "closedAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschlossen seit"])},
  "companyDoesntExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firma wurde nicht gefunden oder existiert nicht"])},
  "userDoesntExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer wurde nicht gefunden oder existiert nicht"])},
  "contactDoesntExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt wurde nicht gefunden oder existiert nicht"])},
  "myTasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meine Aufgaben"])},
  "tooltipProjectPhaseTaskCount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Phase '", _interpolate(_named("phase")), "' hat ", _interpolate(_named("taskCount")), " offene Aufgabe(n)."])},
  "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
  "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thema"])},
  "lightMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hell"])},
  "darkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dunkel"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Optional"])},
  "specialFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sonderfelder"])},
  "loadAllData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Daten laden"])},
  "information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
  "targetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sollwert"])},
  "tolerance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toleranz"])}
}