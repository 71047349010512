<template>
  <div class="mb-2 mt-2">
    <CustomLabel
      class="w-full"
      :label="this.$t('assignment')"
      :input="correspondence.stage">
      <Dropdown
        class="w-full"
        v-model="correspondence.stage"
        :placeholder="this.$t('placeholder.assignment')"
        :options="stageOptions"
      />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel>
      <Editor 
        v-model="correspondence.text"
        editorStyle="height:300px"
        :class="v$.correspondence.text.$error ? 'p-invalid' : ''"
      />
    </CustomLabel>
  </div>

  <div class="pt-2">
    <FileUpload
      ref="fileUpload"
      name="demo[]"
      :customUpload="true"
      @uploader="uploadFiles"
      :multiple="true"
      accept=".jpg,.jpeg,.png,.eml,.msg,.doc,.docx,.pdf,.ppt,.pptx,.txt,.xls,.xlsx"
      :maxFileSize="52000000">
      <template #empty>
        <p>Drag and drop files to here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable 
      :attachments="files"
      :show-delete-button="!isReadOnly"
      @delete-attachment="deleteFile" />
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";
import MSGReader from '@kenjiuno/msgreader';
import sanitizeFileName from "../../../mixins/sanitize-file-name/sanitize-file-name";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import FileUpload from "primevue/fileupload";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  name: "EditDashboardCorrespondence",
  components:{
    CustomLabel, Dropdown, Editor, AttachmentMaterialTable, FileUpload,
  },
  mixins:[globalComputedProperties, S3FileManager, sanitizeFileName],
  data(){
    return{
      files:[],
      correspondence:JSON.parse(JSON.stringify(this.correspondenceProp)),
    }
  },
  props:{
    correspondenceType:String,
    correspondenceProp:Object,
    companyProp:Object,
    userProp:Object,
    contactProp:Object,
  },

  setup(){
    return{
      v$: useVuelidate()
    }
  },

  /**
   * @returns {{correspondence: {text: {required: ValidationRuleWithoutParams}}}}
   */
  validations(){
    return {correspondence: {text:{required}}};
  },

  computed:{
    stageOptions(){
      const foundTagContainer = this.$store.getters.allTagContainers.find(tagContainer => tagContainer.Coverage === "ticket_stages");
      let stageOptions = [];
      if(foundTagContainer){
        for(let tag of foundTagContainer.Tags){
          const foundCustomList = this.$store.getters.allCustomLists.find(customList => customList.Id === tag.TagId);
          if(foundCustomList){
            stageOptions = [...stageOptions, ...foundCustomList.TagNames];
          }
        }
      }

      return stageOptions;
    }
  },

  methods:{
    getFiles(){
      if(Array.isArray(this.correspondence.file)){
        if(this.correspondence.file.length > 0){
          for(let file of this.correspondence.file){
            if(file.path.includes('documents/')){
              this.getS3Objects(file.path).then(res => {
                this.files.push(res[0])
              })
            }else{
              let fileName = this.sanitizeFileName(file.name).trimStart();
              const filePath = this.getFilePath() + fileName;
              this.getS3Objects(filePath).then(res => {
                if(res && res.length > 0){
                  this.files = [...this.files, ...res];
                }
              });
            }
          }  
        }
      }
    },

    uploadFiles(event){
      for (let file of event.files){
        let fileName = this.getFileName(file.name);
        let filePath = this.getFilePath();
        const fileType = this.getFileType(file.name);
       // this.$store.commit('setLoading',true);
        // Check if it's an email file
        if (file.type.includes('message')) {
          // Wait for email content extraction asynchronously
          this.getEmailText(file).then(() => {
            fileName = `${this.$t('date')}: ${this.emailContent.date}` + ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });

              this.correspondence.file.push({
                name: fileName,
                path: filePath,
              });

              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
        }else if (file.name.endsWith('.msg')) {
          this.readMsgFile(file).then((emailData) => {
            fileName = `${this.$t('date')}: ${emailData.date}`+ ' ' + fileName;
            fileName = this.sanitizeFileName(fileName);
            filePath = filePath + fileName.trim();
            this.s3UploadFile(file, filePath).then(() => {
              this.files.push({
                Name:fileName,
                Type:fileType,
                LastModified:new Date(file.lastModified).toLocaleString(),
                Size:(file.size / (1024*1024)).toFixed(2) + " MB",
                "Key": filePath,
              });

              this.correspondence.file.push({
              name: fileName,
              path: filePath,
            });
            
              this.$store.commit('setLoading',false);
            }).catch((err) => {
              console.log(err);
              this.$store.commit('setLoading',false);
            });
          });
         }else{
          fileName = this.sanitizeFileName(fileName);
          filePath = filePath + fileName.trim();
          this.s3UploadFile(file, filePath).then(() => {
            this.files.push({
              Name:fileName,
              Type:fileType,
              LastModified:new Date(file.lastModified).toLocaleString(),
              Size:(file.size / (1024*1024)).toFixed(2) + " MB",
              "Key": filePath,
            });

            this.correspondence.file.push({
              name: fileName,
              path: filePath,
            });

            this.$store.commit('setLoading',false);
          }).catch((err) => {
            console.log(err);
            this.$store.commit('setLoading',false);
          });
        }
      }
    },

    getEmailText(file){
      return new Promise((resolve) => {
        const reader = new FileReader();
        
        // Read the file as text or binary, depending on your email file format (.eml or .msg)
        reader.onload = (e) => {
          const emailContent = e.target.result;
          this.emailContent = this.parseEmailContent(emailContent);
          resolve();
        };
        
        // Read as text (suitable for .eml files)
        reader.readAsText(file);
      });
    },

    async readMsgFile(file) {
      return new Promise((resolve) => {
        const fileReader = new FileReader();

        // When the file is loaded, use msg-parser to parse it
        fileReader.onload = async (e) => {
          const arrayBuffer = e.target.result;

          try {
            const msgReader = new MSGReader(arrayBuffer);
            const fileData = msgReader.getFileData();

            const emailData = {
            date: fileData.creationTime.replace(/,/g, "") || 'Unknown',
          };

          resolve(emailData)

          } catch (err) {
            console.error('Error parsing .msg file:', err);
          }
        };

        // Read the file as an ArrayBuffer
        fileReader.readAsArrayBuffer(file);
      });
    },

    getEmailFromSubject(emailString){
      return emailString.match(/<([^>]+)>/)[1];
    },

    parseEmailContent(emailContent) {
      // Parsing .eml content (simple approach for text-based emails)
      const emailLines = emailContent.split("\n");

      let date = "";

      emailLines.forEach(line => {
        if (line.startsWith("Date:")) {
          date = line.replace("Date:", "").replace(/,/g, "").replace(/\s\+.*/, "").trim();
        }
      });

      return {
        date,
      }
    },

    /**
     * Deletes and removes the clicked file from the table.
     * @param file
     */
    deleteFile(file){
      this.$confirm.require({
        header: this.$t('swal.deleteFileHeader'),
        message: this.$t('swal.deleteFileText'),
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$store.dispatch('deleteS3Object',file.Key).then(() => {
            this.correspondence.file = this.correspondence.file.filter(foundFile => foundFile.Key !== file.Key);
            this.files = this.files.filter(foundFile => foundFile.Key !== file.Key);
            this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.fileDeleted'), life: 3000 });
          });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
        }
      });
    },

    /**
     * If files are selected but not uploaded, we show a confirm dialog.
     */
    checkIfFilesNotUploaded(){
      if(this.$refs.fileUpload.files.length > 0) {
        this.$confirm.require({
          header: this.$t('swal.submitWithoutUploadingHeader'),
          message: this.$t('swal.submitWithoutUploadingText'),
          icon: 'pi pi-exclamation-triangle',
          accept: () => {
            this.addCorrespondence();
          },
          reject: () => {
            this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('swal.rejectedText'), life: 3000 });
          }
        });
      }else{
        this.addCorrespondence();
      }
    },

    updateCorrespondenceArray(entity, correspondence){
      entity.Correspondence = entity.Correspondence.map((currentCorrespondence) => currentCorrespondence.timestamp === correspondence.timestamp ? correspondence : currentCorrespondence);
    },

    /**
     * If there are no errors, adds the new correspondence to the array
     * and updates the store + posts the update to the server.
     * In case the Key Correspondence doesn't exist yet, we add it.
     * Otherwise, we would receive an error.
     */
    addCorrespondence(){
      this.v$.$validate();
      if(!this.v$.$error) {
        const data = {};
        if (this.correspondenceType === this.$t('company')) {
          data.Company = this.companyProp;
          this.updateCorrespondenceArray(data.Company, this.correspondence);
        } else if (this.correspondenceType === this.$t('person')) {
          data.Person = this.userProp;
          this.updateCorrespondenceArray(data.Person, this.correspondence);
        } else if (this.correspondenceType === this.$t('contact')) {
          data.Contact = this.contactProp;
          this.updateCorrespondenceArray(data.Contact, this.correspondence);
        }
        this.$toast.add({ severity: 'info', summary: this.$t('confirmed'), detail: this.$t('swal.correspondenceEdited'), life: 3000 });
        this.$store.dispatch('addOrEditCorrespondence',data).then(() => {
          this.$emit('close-popup');
        });
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('rejected'), detail: this.$t('errorKeysNeeded') + this.getErrors(), life: 5000 });
      }
    },

    /**
     * Returns the filePath depending on our
     * correspondenceType & if a stage is selected.
     * @returns {string}
     */
    getFilePath(){
      let filePath = "";
      if(this.correspondence.stage) {
        if (this.correspondenceType === this.$t('company')) {
          filePath = this.companyProp.Id + '/' + this.correspondence.stage + '/';
        } else if (this.correspondenceType === this.$t('person')) {
          filePath = this.userProp.User + '/' + this.correspondence.stage + '/';
        } else if (this.correspondenceType === this.$t('contact')) {
          filePath = this.contactProp.ContactId + '/' + this.correspondence.stage + '/';
        }
      }else{
        if(this.correspondenceType === this.$t('company')){
          filePath = this.companyProp.Id + '/';
        }else if(this.correspondenceType === this.$t('person')){
          filePath = this.userProp.User + '/';
        }else if(this.correspondenceType === this.$t('contact')){
          filePath = this.contactProp.ContactId + '/';
        }
      }

      return filePath;
    },

    /**
     * Returns a toast with all the errors / required fields.
     * @returns {string}
     */
     getErrors(){
      let errorList = "";
      for(let error of this.v$.$errors){
        if(errorList !== ""){
          errorList = errorList + ", ";
        }
        switch(error.$property){
          case "company":
            errorList = errorList + ' ' + this.$t('company');
            break;
          case "person":
            errorList = errorList + ' ' + this.$t('person');
            break;
          case "contact":
            errorList = errorList + ' ' + this.$t('contact');
            break;
          case "text":
            errorList = errorList + ' ' + this.$t('description');
            break;
        }
      }

      return errorList;
    }
  },

  mounted(){
    if(this.correspondence.file.length > 0){
      this.getFiles();
    }
  },

  watch: {
    correspondenceProp(newVal) {
      if (newVal) {
        this.files = [];
        this.correspondence = newVal;
        if(newVal.file.length > 0){
          this.getFiles();
        }
      }
    },
  }
}
</script>

<style scoped>

</style>