<template>
  <div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          stateStorage="local"
          stateKey="leadQueryTable"
          removableSort
          v-model:filters="filter"
          filterDisplay="menu"
          :globalFilterFields="globalFilter"
          selectionMode="single"
          @rowSelect="showEditLead">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="shouldContinue" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  size="small"
                  severity="danger"
                  @click="cancelGetData">
                  {{$t('cancel')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="getMoreLeads">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="loadAllData">
                  {{$t('loadAllData')}}
                </PrimeButton>
              </div>

              <PrimeButton style="height:36px;min-width:36px"
                          label="Excel"
                          icon="pi pi-file-excel"
                          class="mr-2"
                          outlined
                          severity="secondary"
                          @click="exportToExcel" />

              <IconField
                iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filter['global'].value"
                  :placeholder="$t('placeholder.search')"
                  :style="isDesktop ? '' : 'width:200px;'" />
              </IconField>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            :sortable="col.field !== 'CreationDate'"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="getToolTip(data,field)">
                {{ data[field]}}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
          <Column v-for="(col,index) of leadTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="true"
            :columnKey="col.field"
            :sortable="true"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>

  <Popup
    :modal="isDesktop"
    :visible="editingLead"
    :header="this.$t('editLeads')"
    styling="width:90%"
    :show-save-button="showSaveButton"
    @button-clicked="submitEditLeadPopup"
    @close-popup="editingLead = false"
    :show-inline-message="true"
    :inline-message="inlineMessage">
    <EditLead
      ref="editLead"
      :lead-data-prop="selectedLead"
      :disabled="selectedLead.Status === '20'"
      @submit-edit-lead="submitLead"/>
  </Popup>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import Popup from "../../global-components/popup/Popup.vue";
import EditLead from "../edit-lead/EditLead.vue";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "LeadQueryTable",
  mixins:[QueryBuilderFunctions, globalComputedProperties],
  data() {
    return {
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible', header: this.$t('responsible') },
        { field: 'Company', header: this.$t('company') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Prio', header: this.$t('prio') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'Classification', header: this.$t('classification') },
        { field: 'CreationDate', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Responsible:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Company:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Creator:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Description:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Prio:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Supplier:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Classification:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CreationDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter:['Title', 'Responsible', 'Company', 'Creator', 'Description', 'Prio', 'Supplier', 'Classification', 'CreationDate'],
      leadTagColumns: [],
      windowHeight:'',
      editingLead:false,
      selectedLead:{},
      inlineMessage:'',
      showSaveButton:true,
    };
  },
  components: {
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon, Popup, EditLead
  },

  computed:{
   gridDataSource(){
    return this.prepareDataSource(this.$store.getters.leads);
   },

    lastEvaluatedKey(){
      return this.$store.getters.openLeadsLastEvaluatedKey;
    },

    shouldContinue(){
      return this.$store.getters.shouldContinue;
    }
  },

  methods: {
    prepareDataSource(leads){
      const src = [];
      const tags = this.$store.getters.allCustomLists;

      for (let lead of leads){
        let obj = {};
        obj["Title"] = lead["Title"];
        obj["Creator"] = lead["Creator"];
        obj["Description"] = this.stripHtmlTags(lead["Description"]);
        obj["Prio"] = lead["Prio"];
        obj["Supplier"] = lead["Supplier"];

        // the table cannot display nested values so we modify / prepare the data.
        obj["Company"] = this.getValFromObject(lead.Company, "Name") + " " + this.getValFromObject(lead.Company, "CompanyNumber");
        obj["Responsible"] = this.getValFromObject(lead.Responsible, "Name");
        obj["Classification"] = this.getValFromObject(lead.LeadTagContainer, "ContainerName");

        obj["CreationDate"] = new Date(Number(lead.Created)).toLocaleDateString();

        if(lead.LeadTags && Object.keys(lead.LeadTags).length > 0){
          for(let tag of tags){
            if(lead.LeadTags[tag.Id]){
              obj[tag.ClusterName] = lead.LeadTags[tag.Id].toString();
            }
          }
        }

        obj['Id'] = lead['Id'];
        src.push(obj);
      }
      return src;
    },
    
    stripHtmlTags(htmlString){
      return htmlString.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
    },

    getToolTip(data,field){
      return data[field];
    },

    initializeLeadTagFilter() {
      this.leadTagColumns.forEach((col) => {
        this.filter[col.field] = {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const leadTags = [];
      const tags = this.$store.getters.allCustomLists;
      const leadContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Leads');
      for(let container of leadContainers){
        for(let tag of container.Tags){
          const foundLeadTag = tags.find((currentTag) => currentTag.Id === tag.TagId);
          if(foundLeadTag){
            const tagAlreadyExists = leadTags.some(tag => tag.header === foundLeadTag.ClusterName);
            if(!tagAlreadyExists){
              leadTags.push({
                header: foundLeadTag.ClusterName,
                field:foundLeadTag.ClusterName
              });
            }
          }
        }
      }
      return leadTags;
    },

    exportToExcel(){
      // Filter the data based on active filters
      const filteredData = this.gridDataSource.filter(row => {
        return [...this.columns, ...this.leadTagColumns].every(column => {
          const columnFilter = this.filter[column.field]?.constraints; // Get filter constraints
          if (!columnFilter || columnFilter.length === 0) return true; // No filter, include row

          const cellValue = row[column.field]?.toString().toLowerCase(); // Convert cell value to string
          return columnFilter.some(constraint => {
            const filterValue = constraint.value?.toString().toLowerCase(); // Convert filter value to string
            if (!filterValue) return true; // Empty filter constraint, include row

            // Apply filtering logic based on match mode
            switch (constraint.matchMode) {
              case 'startsWith':
                return cellValue?.startsWith(filterValue);
              case 'contains':
                return cellValue?.includes(filterValue);
              case 'notContains':
                return !cellValue?.includes(filterValue);
              case 'endsWith':
                return cellValue?.endsWith(filterValue);
              case 'equals':
                return cellValue === filterValue;
              case 'notEquals':
                return cellValue !== filterValue;
              default:
                return true; // If match mode is unknown, include row
            }
          });
        });
      });

      // Map the filtered data to include necessary columns
      const exportData = filteredData.map(row => {
        const filteredRow = {};
        [...this.columns, ...this.leadTagColumns].forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Set custom headers
      let headers = [...this.columns, ...this.leadTagColumns].map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'leads_workbook.xlsx');
    },

    showEditLead(row){
      const foundLead = this.$store.getters.leads.find((lead) => lead.Id === row.data.Id);
      this.showSaveButton = foundLead.Status === '10';
      if(foundLead.Status === '10'){
        this.inlineMessage = this.$t('openSince') + ' ' + this.getDueDate(foundLead.Created) + ' ' +this.$t('days');
      }else{
        this.inlineMessage = this.$t('closedAt') + ' ' + this.getDueDate(foundLead.Closed) + ' ' + this.$t('days');
      }
      
      this.editingLead = true;
      this.selectedLead = foundLead;
    },

    /**
     * Calculates the due date of the lead.
     * @param unix
     * @returns {number}
     */
    getDueDate(unix){
      const nowUnix = new Date().getTime() / 1000;
      const timeStamp = unix / 1000;

      return (Math.round((nowUnix - timeStamp) / 60 / 60 / 24))
    },

    submitEditLeadPopup(){
      this.$refs.editLead.checkIfFilesNotUploaded();
    },

    /**
     * Dispatches editLead to store and closes the popup while emptying the selectedLead as well.
     * @param editedLeadData
     */
    submitLead(editedLeadData){
      editedLeadData.LastEdited = new Date().getTime();
      this.$store.dispatch('editLead',editedLeadData).then(() => {
        this.$toast.add({ severity: 'success', summary: this.$t('confirmed'), detail: this.$t('swal.leadEdited'), life: 3000 });
        if(this.$store.getters.companyDashboard && 
          this.$router.currentRoute._value.name === 'Company Dashboard' &&
          this.$router.currentRoute._value.params.Id === editedLeadData.Company.Id) {
            this.$store.commit('setShouldReloadDashboard', true);
          }
        this.editingLead = false;
        this.selectedLead = {};
      });
    },

    getMoreLeads(){
      if(this.lastEvaluatedKey && this.lastEvaluatedKey.Created && this.lastEvaluatedKey.Id){
        const id = Number(this.lastEvaluatedKey["Id"]);
        const created = Number(this.lastEvaluatedKey["Created"]);
        this.$store.commit('setLoading', true);
        try{
          this.$store.dispatch("getRequest","getMoreLeads&query=" +  [id, created]).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
              this.$store.commit('setLeadsData', body.Items);

              if (body.LastEvaluatedKey && body.Items.length > 0) {
                this.$store.commit('setOpenLeadsLastEvaluatedKey', body.LastEvaluatedKey);
              }else{
                this.$store.commit('setOpenLeadsLastEvaluatedKey', null);
              }
            }
          });
        }catch(err){
          console.log(err);
          this.$stre.commit('setLoading', false);
        }
      }else{
        this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
      }
    },

    loadAllData(){
      this.$store.commit('setShouldContinue', true);
      this.getAllLeads();
    },

    // Not sure why but it works if we directly check the store
    // the computed property doesnt update correctly in the async function
    async getAllLeads(lastKey = null){
      if (!this.$store.getters.shouldContinue) {
        return;
      }

      try {
        const queryKey = lastKey 
          ? [lastKey["Id"], lastKey["Created"]] 
          : [this.lastEvaluatedKey?.Id, this.lastEvaluatedKey?.Created];
        const data = await this.$store.dispatch('getRequest', 'getMoreLeads&query=' + queryKey);

        if (data) {
          const body = JSON.parse(data.body);
          this.$store.commit('setOpenLeadsLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setLeadsData', body['Items']);

          if (body['LastEvaluatedKey'] && this.$store.getters.shouldContinue) {
            await this.getAllLeads(body['LastEvaluatedKey']);
          } else {
            this.$store.commit('setShouldContinue', false);
          }
        }
      } catch (error) {
        this.$store.commit('setShouldContinue', false);
      }
    },

    cancelGetData() {
      this.$store.commit('setShouldContinue', false);
    }
  },
  created(){
    this.leadTagColumns = this.createTagsColumns();
    this.initializeLeadTagFilter();
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
}
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td){
  padding: 8px 21px !important;
}
</style>