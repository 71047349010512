<template>
  <div class="col-12">
    <TabView :activeIndex="activeIndex">
      <TabPanel :header="this.$t('active') + ' (' + companyActiveUsers.length + ')'">
        <MaterialTable :data-key="'Id'"
          :table-id="'companyUsers'"
          :style="{'height': windowHeight}"
          :data-prop="companyActiveUsers"
          :columns="columns"
          @row-selected="goToUser"
          :has-paginator="true"
          :rows-count="10"
          :has-global-filter="true"
          :show-toggle-column="true"
          :filter-prop="filter"
          :filter-options="filterOptions"
          :sortable="true"
          :has-removable-sort="true"
          :key="tableKey"
          :selection-mode="'single'"
          :resizable-columns="true"/>
      </TabPanel>

      <TabPanel :header="this.$t('retired') + ' (' + companyRetiredUsers.length + ')'">
        <MaterialTable :data-key="'Id'"
          :table-id="'companyUsers'"
          :style="{'height': windowHeight}"
          :data-prop="companyRetiredUsers"
          :columns="columns"
          @row-selected="goToUser"
          :has-paginator="true"
          :rows-count="10"
          :has-global-filter="true"
          :show-toggle-column="true"
          :filter-prop="filter"
          :filter-options="filterOptions"
          :sortable="true"
          :has-removable-sort="true"
          :key="tableKey"
          :selection-mode="'single'"
          :resizable-columns="true"/>
      </TabPanel>
    </TabView>
  </div>

  
</template>

<script>
import {FilterMatchMode} from "primevue/api";
import MaterialTable from "@/components/global-components/material-table/MaterialTable";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";

export default {
  name: "CompanyMaterialTableUserTab",
  components:{MaterialTable, TabView, TabPanel},
  props:{
    companyUsers:Array,
  },
  data(){
    return{
      companyUsersData:JSON.parse(JSON.stringify(this.companyUsers)),
      columns:[
        { field: 'Name', header: this.$t('name') },
        { field: 'Department', header: this.$t('department') },
        { field: 'Position', header: this.$t('position') },
        { field: 'Mobile', header: this.$t('mobile') },
        { field: 'Type', header: this.$t('type') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Department: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Position: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Mobile: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Type: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      filterOptions:['Name', 'Department', 'Position', 'Mobile', 'Type'],
      tableKey:0,
      windowHeight: '',
    }
  },

  computed:{
    companyActiveUsers(){
      return this.companyUsersData.filter((user) => !user.Retired);
    },

    companyRetiredUsers(){
      return this.companyUsersData.filter((user) => user.Retired);
    },
  },

  methods:{
    goToUser(person){
      if(person.Type === "Contact"){
        this.$router.push({
          path:"/persons/contactDashboard/" + person.Id,
          params:person.Id,
        });
      }else{
        this.$router.push({
          path:"/persons/dashboard/" + person.Id,
          params:person.Id,
        });
      }
    },
  },

  mounted(){
    this.windowHeight = window.innerHeight * 0.52 + 'px';
  }
}
</script>

<style scoped>

</style>