<template>
<div style="min-height: 600px">
    <div class="col-lg-12 control-section">
      <div class="content-wrapper">
        <DataTable
          ref="dataTable"
          :value="gridDataSource"
          paginator
          :rows="20"
          :rowsPerPageOptions="[10, 20, 50]"
          scrollable
          :scrollHeight="windowHeight"
          dataKey="Id"
          stateStorage="local"
          stateKey="projectQueryTable"
          removableSort
          v-model:filters="filter"
          filterDisplay="menu"
          selectionMode="single"
          @rowSelect="goToProjectDashboard"
          @sort="dataSort"
          :globalFilterFields="globalFilter">
          <template #header>
            <div class="flex xl:justify-content-end align-items-center">
              <div v-if="shouldContinue" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                  class="mr-2"
                  size="small"
                  severity="danger"
                  @click="cancelGetData">
                  {{$t('cancel')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="getMoreProjects">
                  {{$t('loadMoreData')}}
                </PrimeButton>
              </div>
              <div v-if="lastEvaluatedKey" class="flex align-items-center gap-2">
                <PrimeButton style="height:36px;"
                            class="mr-2"
                            outlined
                            size="small"
                            severity="secondary"
                            @click="loadAllData">
                  {{$t('loadAllData')}}
                </PrimeButton>
              </div>

              <PrimeButton style="height:36px;min-width:36px"
                          label="Excel"
                          icon="pi pi-file-excel"
                          class="mr-2"
                          outlined
                          severity="secondary"
                          @click="exportToExcel" />

              <IconField
                iconPosition="left">
                <InputIcon>
                  <i class="pi pi-search" />
                </InputIcon>
                <InputText v-model="filter['global'].value"
                  :placeholder="$t('placeholder.search')"
                  :style="isDesktop ? '' : 'width:200px;'" />
              </IconField>
            </div>
          </template>
          <Column v-for="(col,index) of columns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :columnKey="col.field"
            :showFilterMatchModes="true"
            :sortable="true"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">
                {{ data[field] }}
              </span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
          <Column v-for="(col,index) of projectTagColumns"
            :key="col.field + '_' + index" :field="col.field"
            :filterField="col.field"
            :showFilterMatchModes="true"
            :columnKey="col.field"
            sortable
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
            <template #header>
              <span v-tooltip.top="col.header"
                class="headerLabel">{{ col.header }}</span>
            </template>
            <template #body="{data, field}">
              <span v-tooltip.top="data[field]">{{ data[field] }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" placeholder="Any" />
            </template>
            <template #filterclear="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-times" @click="filterCallback()" severity="secondary"></PrimeButton>
            </template>
            <template #filterapply="{ filterCallback }">
              <PrimeButton type="button" icon="pi pi-check" @click="filterCallback()" severity="success"></PrimeButton>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import QueryBuilderFunctions from "@/mixins/query-builder-functions/queryBuilderFunctions";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import InputText from "primevue/inputtext";
import PrimeButton from "primevue/button";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "ProjectQueryTable",
  mixins:[QueryBuilderFunctions, globalComputedProperties],
  data() {
    return { 
      columns:[
        { field: 'Title', header: this.$t('title') },
        { field: 'Responsible', header: this.$t('responsible') },
        { field: 'Company', header: this.$t('company') },
        { field: 'Creator', header: this.$t('creator') },
        { field: 'Description', header: this.$t('description') },
        { field: 'Phase', header: this.$t('phase') },
        { field: 'Supplier', header: this.$t('supplier') },
        { field: 'LatestContact', header: this.$t('lastContact') },
        { field: 'ProjectType', header: this.$t('type') },
        { field: 'ProjectClassification', header: this.$t('classification') },
        { field: 'CreationDate', header: this.$t('creationDate') },
      ],
      filter:{
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        Title:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Responsible:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Company:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Creator:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Description:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Phase:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        Supplier:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        LatestContact:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ProjectType:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        ProjectClassification:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
        CreationDate:{operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]},
      },
      globalFilter:['Title', 'Responsible', 'Company', 'Creator', 'Description', 'Phase', 'Supplier', 'LatestContact', 'ProjectType', 'ProjectClassification', 'CreationDate'],
      projectTagColumns: [],
      windowHeight:'',
    };
  },

  components: {
    DataTable, Column, InputText, PrimeButton, IconField, InputIcon
  },
  computed:{
    gridDataSource(){
      return this.prepareDataSource(this.$store.getters.projects);
    },

    getColumnFilterOptions() {
      return (field) => {
        // Extract unique values for the given field
        const uniqueValues = new Set(
          this.gridDataSource.map(item => item[field]).filter(value => value != null) // filter out null/undefined
        );
        if(field === 'LatestContact'){
          return Array.from(uniqueValues).map(value => ( value )).sort((a,b) => {
            if (a > b) return -1;
            if (a < b) return 1;
          });
        }else{
          return Array.from(uniqueValues).map(value => ( value )).sort((a,b) => {
            if (a < b) return -1;
            if (a > b) return 1;
          });
        }
      };
    },

    lastEvaluatedKey(){
      return this.$store.getters.openProjectsLastEvaluatedKey;
    },

    shouldContinue(){
      return this.$store.getters.shouldContinue;
    }
  },
  methods: {
    prepareDataSource(projects){
      const src = [];
      const tags = this.$store.getters.allCustomLists;
      for (let project of projects){
        let obj = {};
        obj["Title"] = project["Title"];
        obj["Responsible"] = this.getValFromObject(project.Responsible, "Name");
        obj["Company"] = this.getValFromObject(project.Company, "Name") + " " + this.getValFromObject(project.Company, "CompanyNumber");
        obj["Creator"] = project["Creator"];
        obj["Description"] = this.stripHtmlTags(project["Description"]);
        obj["Phase"] =  this.getValFromObject(project.Phase, "Name");
        obj["Supplier"] = project["Supplier"];
        obj["LatestContact"] = this.getLatestContact(project);
        obj["ProjectType"] = project["ProjectType"] ? project["ProjectType"] : "-";
        // the table cannot display nested values so we modify / prepare the data.
        obj["ProjectClassification"] = this.getValFromObject(project.ProjectTagContainer, "ContainerName");
        obj["CreationDate"] = new Date(Number(project.Created)).toLocaleDateString();

        if(project.ProjectTags && Object.keys(project.ProjectTags).length > 0){
          for(let tag of tags){
            if(project.ProjectTags[tag.Id]){
              obj[tag.ClusterName] = project.ProjectTags[tag.Id].toString();
            }
          }
        }

        obj["Id"] = project["Id"];

        src.push(obj);
      }
      return src;
    },

    getLatestContact(project){
      let latestContact = project.Created;
      if(project.Correspondence && project.Correspondence.length > 0){
        latestContact =  project.Correspondence.reduce((latest, current) => {
          return current.Unix > latest.Unix ? current : latest;
        }, project.Correspondence[0].Unix);
      }

      return new Date(Number(latestContact)).toLocaleDateString();
    },

    dataSort(event){
      const field = event.sortField;
      const order = event.sortOrder;

      if(field === 'LatestContact' || field === 'CreationDate'){
        this.gridDataSource.sort((a, b) => {
          const dateA = this.parseDate(a[field]);
          const dateB = this.parseDate(b[field]);

          if (dateA < dateB) return order === 1 ? -1 : 1;
          if (dateA > dateB) return order === 1 ? 1 : -1;
          return 0;
        });
      }
    },

    parseDate(dateString) {
      // Assumes dateString is in the format dd/mm/yyyy
      const [day, month, year] = dateString.split(".").map(Number);
      return new Date(year, month - 1, day); // JavaScript Date months are zero-indexed
    },

    stripHtmlTags(htmlString){
      return htmlString.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
    },

    goToProjectDashboard(args){
      const projectId = args.data.Id;
      this.$router.push({
        path:"/projects/dashboard/" + projectId,
        params:projectId
      });
    },
    
    initializeProjectTagFilter() {
      this.projectTagColumns.forEach((col) => {
        this.filter[col.field] = {operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }]};  // Direct assignment to filter object
        this.globalFilter.push(col.header);
      });
    },

    createTagsColumns(){
      const projectTags = [];
      const tags = this.$store.getters.allCustomLists;
      const projectContainers = this.$store.getters.allTagContainers.filter((container) => container.Coverage === 'Projects');
      for(let container of projectContainers){
        for(let tag of container.Tags){
          const foundProjectTag = tags.find((currentTag) => currentTag.Id === tag.TagId);
          if(foundProjectTag){
            const tagAlreadyExists = projectTags.some(tag => tag.header === foundProjectTag.ClusterName);
            if(!tagAlreadyExists){
              projectTags.push({
                header: foundProjectTag.ClusterName,
                field:foundProjectTag.ClusterName
              });
            }
          }
        }
      }
      return projectTags;
    },

    exportToExcel(){
       // Filter the data based on active filters
       const filteredData = this.gridDataSource.filter(row => {
        return [...this.columns, ...this.projectTagColumns].every(column => {
          const columnFilter = this.filter[column.field]?.constraints; // Get filter constraints
          if (!columnFilter || columnFilter.length === 0) return true; // No filter, include row

          const cellValue = row[column.field]?.toString().toLowerCase(); // Convert cell value to string
          return columnFilter.some(constraint => {
            const filterValue = constraint.value?.toString().toLowerCase(); // Convert filter value to string
            if (!filterValue) return true; // Empty filter constraint, include row

            // Apply filtering logic based on match mode
            switch (constraint.matchMode) {
              case 'startsWith':
                return cellValue?.startsWith(filterValue);
              case 'contains':
                return cellValue?.includes(filterValue);
              case 'notContains':
                return !cellValue?.includes(filterValue);
              case 'endsWith':
                return cellValue?.endsWith(filterValue);
              case 'equals':
                return cellValue === filterValue;
              case 'notEquals':
                return cellValue !== filterValue;
              default:
                return true; // If match mode is unknown, include row
            }
          });
        });
      });

      // Map the filtered data to include necessary columns
      const exportData = filteredData.map(row => {
        const filteredRow = {};
        [...this.columns, ...this.projectTagColumns].forEach(column => {
          filteredRow[column.field] = row[column.field];
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(exportData);

      // Set custom headers
      let headers = this.columns.map(col => col.header);
      headers = [...headers, ...this.projectTagColumns.map(col => col.header)];
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'projekte_workbook.xlsx');
    },
    
    getMoreProjects(){
      if(this.lastEvaluatedKey && this.lastEvaluatedKey.Created && this.lastEvaluatedKey.Id){
        const created = Number(this.lastEvaluatedKey["Created"]);
        const id = this.lastEvaluatedKey["Id"];
        this.$store.commit('setLoading', true);
        try{
          this.$store.dispatch("getRequest","getMoreProjects&query=" + [id, created]).then(resp => {
            if (resp && resp.statusCode === 200) {
              const body = JSON.parse(resp.body);
              this.$toast.add({ severity: 'success', summary: this.$t('done'), detail: this.$t('swal.dataUpToDate'), life: 3000 });
              this.$store.commit('setOpenProjects', body.Items);
              this.prepareDataSource(body.Items);

              if (body.LastEvaluatedKey && body.Items.length > 0) {
                this.$store.commit('setOpenProjectsLastEvaluatedKey', body.LastEvaluatedKey);
              }else{
                this.$store.commit('setOpenProjectsLastEvaluatedKey', null);
              }
            }else{
              this.$toast.add({ severity: 'error', summary: this.$t('Info'), detail: this.$t('swal.allDataReceived'), life: 5000 });
            }
            this.$store.commit('setLoading', false);
          });
        }catch(err){
          console.log(err);
          this.$store.commit('setLoading', false);
        }
      }
    },

    loadAllData(){
      this.$store.commit('setShouldContinue', true);
      this.getAllProjects();
    },

    // Not sure why but it works if we directly check the store
    // the computed property doesnt update correctly in the async function
    async getAllProjects(lastKey = null){
      if (!this.$store.getters.shouldContinue) {
        return;
      }

      try {
        const queryKey = lastKey 
          ? [lastKey["Id"], lastKey["Created"]] 
          : [this.lastEvaluatedKey?.Id, this.lastEvaluatedKey?.Created];
        const data = await this.$store.dispatch('getRequest', "getMoreProjects&query=" + queryKey);

        if (data) {
          const body = JSON.parse(data.body);
          this.$store.commit('setOpenProjectsLastEvaluatedKey', body["LastEvaluatedKey"]);
          this.$store.commit('setOpenProjects', body['Items']);
          this.prepareDataSource(body.Items);

          if (body['LastEvaluatedKey'] && this.$store.getters.shouldContinue) {
            await this.getAllProjects(body['LastEvaluatedKey']);
          } else {
            this.$store.commit('setShouldContinue', false);
          }
        }
      } catch (error) {
        this.$store.commit('setShouldContinue', false);
      }
    },

    cancelGetData() {
      this.$store.commit('setShouldContinue', false);
    }
  },
  created(){
    this.projectTagColumns = this.createTagsColumns();
    this.initializeProjectTagFilter();
    this.windowHeight = window.innerHeight * 0.56 + 'px';
  }
};
</script>

<style scoped>
.headerLabel{
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-multiselect-label){
  display: flex;
  justify-content: space-between;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
}

:deep(.p-datatable .p-datatable-tbody > tr > td){
  padding: 8px 21px !important;
}
</style>
