<template>
  <PrimeDialog
    :visible="visible"
    :autoZIndex="true"
    :maximizable="maximizable"
    :modal="modal"
    :draggable="false"
    :position="position"
    :header="header"
    :style="styling"
    @update:visible="emitClosePopup()">
    <template #header>
      <div class="flex justify-content-between align-items-center align-content-center w-full">
        <div>
          <span class="font-bold text-xl">{{header}}</span>
        </div>
        <div>
          <PrimeButton
            v-if="showMenuButton"
            icon="pi pi-ellipsis-h"
            plain
            text
            severity="contrast"
            @click="openContextMenu"
            aria-haspopup="true"
            aria-controls="overlay_menu" />
          <PrimeMenu
            :model="menuOptions"
            :popup="true"
            ref="menu"
            id="overlay_menu"/>
          <PrimeButton
            v-if="showSaveButton"
            icon="pi pi-save"
            outlined
            rounded
            severity="contrast"
            @click="emitButton" />
          <!--<PrimeButton
            v-if="showSaveButton"
            icon="pi pi-minimize"
            rounded
            severity="contrast"
            @click="emitMinimize" />-->
        </div>
      </div>
    </template>
    <div v-if="showInlineMessage" class="w-full mb-2 mt-2">
      <InlineMessage severity="warn">{{inlineMessage}}</InlineMessage>
    </div>
    <slot></slot>
    <template v-if="footer" #footer>
      <slot name="footer"></slot>
    </template>
  </PrimeDialog>

</template>

<script>
import PrimeDialog from "primevue/dialog";
import PrimeButton from "primevue/button";
import PrimeMenu from "primevue/menu";
import InlineMessage from "primevue/inlinemessage";

export default {
  name: "PopupComponent",
  components:{
    PrimeDialog, PrimeButton, PrimeMenu, InlineMessage
  },
  emits: ['closePopup', 'buttonClicked'],
  props:{
    visible:Boolean,
    header:String,
    maximizable:Boolean,
    maximized:Boolean,
    modal:Boolean,
    position:String,
    footer:Boolean,
    styling:String,
    showMenuButton:Boolean,
    menuOptions:Array,
    showSaveButton:Boolean,
    showInlineMessage:Boolean,
    inlineMessage:String,
  },
  methods:{
    emitClosePopup(){
      this.$emit("close-popup");
    },

    openContextMenu(event){
      this.$refs.menu.toggle(event);
    },

    emitButton(){
      this.$emit('button-clicked');
    },

    emitMinimize(){
      this.$emit('minimize-popup')
    }
  }
}
</script>

<style>

</style>