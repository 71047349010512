<template>
  <div class="mb-2 mt-2">
    <CustomLabel class="w-full"
                 :label="this.$t('assignment')"
                 :input="correspondence.stage">
      <Dropdown class="w-full"
                :modelValue="correspondence.stage"
                :options="stageOptions"
                disabled="true" />
    </CustomLabel>
  </div>

  <div class="mb-2 mt-2">
    <CustomLabel>
      <Editor :modelValue="correspondence.text"
              editorStyle="height:300px"
              readonly />
    </CustomLabel>
  </div>

  <div v-if="files.length > 0">
    <AttachmentMaterialTable :attachments="files" />
  </div>
</template>

<script>
import CustomLabel from "@/components/global-components/custom-label/CustomLabel";
import Dropdown from "primevue/dropdown";
import Editor from "primevue/editor";
import AttachmentMaterialTable from "@/components/global-components/attachment-material-table/AttachmentMaterialTable";
import S3FileManager from "@/mixins/s3-file-manager/s3-file-manager";

export default {
  name: "ViewCorrespondence",
  components:{
    CustomLabel, Dropdown, Editor, AttachmentMaterialTable,
  },
  mixins:[S3FileManager],
  data(){
    return{
      files:[],
      correspondence:JSON.parse(JSON.stringify(this.correspondenceProp)),
    }
  },
  props:{
    correspondenceType:String,
    correspondenceProp:Object,
    companyId:String,
    userId:String,
    contactId:String,
  },
  computed:{
    stageOptions(){
      const foundTagContainer = this.$store.getters.allTagContainers.find(tagContainer => tagContainer.Coverage === "ticket_stages");
      let stageOptions = [];
      if(foundTagContainer){
        for(let tag of foundTagContainer.Tags){
          const foundCustomList = this.$store.getters.allCustomLists.find(customList => customList.Id === tag.TagId);
          if(foundCustomList){
            stageOptions = [...stageOptions, ...foundCustomList.TagNames];
          }
        }
      }

      return stageOptions;
    }
  },

  methods:{
    getFiles(){
      if(Array.isArray(this.correspondence.file)){
        if(this.correspondence.file.length > 0){
          for(let file of this.correspondence.file){
            if(file.path.includes('documents/')){
              this.getS3Objects(file.path).then(res => {
                this.files.push(res[0])
              })
            }else{
              const filePath = this.getFilePath(file);
              this.getS3Objects(filePath).then(res => {
                if(res && res.length > 0){
                  this.files = [...this.files, ...res];
                }
              });
            }
          }  
        }
      }
    },

    /**
     * Returns the filePath depending on our
     * correspondenceType & if a stage is selected.
     * @param file
     * @returns {string}
     */
    getFilePath(file){
      let filePath = "";
      if(this.correspondence.stage) {
        if (this.correspondenceType === this.$t('company')) {
          filePath = this.companyId + '/' + this.correspondence.stage + '/' + file.name.trim();
        } else if (this.correspondenceType === this.$t('person')) {
          filePath = this.userId + '/' + this.correspondence.stage + '/' + file.name.trim();
        } else if (this.correspondenceType === this.$t('contact')) {
          filePath = this.contactId + '/' + this.correspondence.stage + '/' + file.name.trim();
        }
      }else{
        if(this.correspondenceType === this.$t('company')){
          filePath = this.companyId + '/' + file.name.trim();
        }else if(this.correspondenceType === this.$t('person')){
          filePath = this.userId + '/' + file.name.trim();
        }else if(this.correspondenceType === this.$t('contact')){
          filePath = this.contactId + '/' + file.name.trim();
        }
      }

      return filePath;
    },
  },

  mounted(){
    if(this.correspondence.file.length > 0){
      this.getFiles();
    }
  },

  watch: {
    correspondenceProp(newVal) {
      if (newVal) {
        this.files = [];
        this.correspondence = newVal;
        if(newVal.file.length > 0){
          this.getFiles();
        }
      }
    },
  }
}
</script>

<style scoped>

</style>