<template>
  <DataTable
    :value="dataProp"
    :removableSort="hasRemovableSort"
    ref="materialTable"
    :sortMode="sortMode"
    :paginator="hasPaginator"
    :rows="rowsCount"
    :rowsPerPageOptions="[5, 10, 20, 50]"
    :dataKey="dataKey"
    :showGridlines="showGridlines"
    stateStorage="local"
    :stateKey="tableId"
    :reorderableColumns="true"
    scrollable
    scrollHeight="flex"
    v-model:editingRows="editingRows"
    editMode="row"
    :resizableColumns="resizableColumns"
    columnResizeMode="fit"
    v-model:filters="filters"
    :globalFilterFields="filterOptions"
    :filterDisplay="filterDisplay"
    :metaKeySelection="false"
    v-model:selection="selectedOption"
    :selectionMode="selectionMode"
    @rowSelect="emitRowSelected"
    @rowUnselect="emitRowSelected"
    :style="fullscreen ? 'min-height:100vh; width:100%; position:fixed; top:0; left:0; right:0;z-index:20; bottom:0;' : ''">
    <template #header>
      <div class="flex xl:justify-content-end align-items-center">
        <div v-if="showCancelLoadButton" 
          class="mr-2">
          <PrimeButton
            style="height:36px;min-width:36px"
            :label="$t('cancel')"
            class="mr-2"
            size="small"
            severity="danger"
            @click="cancelLoadAllData" />
        </div>
        <div v-if="showLoadMoreData" 
          class="flex align-items-center gap-2">
          <span v-if="showLoadMoreData.SalesDate">{{$t('dataLoadedTill')}} {{new Date(Number(showLoadMoreData.SalesDate)).toLocaleDateString()}}</span>
          <span v-if="showLoadMoreData.Created">{{$t('dataLoadedTill')}} {{new Date(Number(showLoadMoreData.Created)).toLocaleDateString()}}</span>
          <PrimeButton
            style="height:36px;min-width:36px"
            icon="pi pi-sync"
            :label="$t('loadMoreData')"
            class="mr-2"
            outlined
            size="small"
            severity="secondary"
            @click="emitLoadMoreData" />
        </div>
        <div v-if="showLoadMoreData" 
          class="mr-2">
          <PrimeButton
            style="height:36px;min-width:36px"
            icon="pi pi-sync"
            :label="$t('loadAllData')"
            class="mr-2"
            outlined
            size="small"
            severity="secondary"
            @click="emitLoadAllData" />
        </div>

        <PrimeButton
          v-if="showAddButton"
          style="height:36px;min-width:36px"
          icon="pi pi-plus"
          class="mr-2"
          outlined
          severity="secondary"
          @click="emitOpenDialog" />

        <PrimeButton
          style="height:36px;min-width:36px"
          label="Excel"
          icon="pi pi-file-excel"
          class="mr-2"
          outlined
          severity="secondary"
          @click="exportToExcel" />

        <PrimeButton
          v-if="!fullscreen"
          style="height:36px;min-width:36px"
          :label="$t('maximize')"
          icon="pi pi-window-maximize"
          class="mr-2"
          outlined
          severity="secondary"
          @click="setFullscreen" />

        <PrimeButton
          v-else style="height:36px;min-width:36px"
          :label="$t('minimize')"
          icon="pi pi-window-minimize"
          class="mr-2"
          outlined
          severity="secondary"
          @click="setFullscreen" />

        <ToggleButton
          v-if="isDesktop"
          v-model="filterToggle"
          class="ml-2 mr-2 border-round-3xl"
          @change="toggleFilter"
          :onLabel="$t('filter')"
          onIcon="pi pi-filter"
          :offLabel="$t('filter')"
          offIcon="pi pi-filter-slash" />
          
        <MultiSelect
          v-if="showToggleColumn && isDesktop"
          class="mr-2"
          :modelValue="selectedColumns"
          :options="columns"
          optionLabel="header"
          @update:modelValue="onToggle"
          display="chip"
          placeholder="Select Columns"
          style="max-width:200px" />

        <IconField v-if="hasGlobalFilter"
          iconPosition="left">
          <InputIcon>
            <i class="pi pi-search" />
          </InputIcon>
          <InputText v-model="filters['global'].value"
            :placeholder="$t('placeholder.search')"
            :style="isDesktop ? '' : 'width:200px;'" />
        </IconField>
      </div>
    </template>
    <Column v-if="showConvertButton && isDesktop || showCloseButton && isDesktop || showDeleteButton && isDesktop"
            :exportable="false"
            :columnKey="'Buttons'"
            style="max-width: 200px; min-width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis"
            :style="buttonCount >= 2 ?  { 'max-width': '200px' } : { 'max-width': '67px' }">
      <template #body="slotProps">
        <PrimeButton
          v-if="showConvertButton && allowToConvert(slotProps)"
          v-tooltip="$t('swal.convertLeadHeader')"
          class="mr-1"
          icon="pi pi-sign-in"
          outlined
          rounded
          severity="success"
          style="height:40px;width:40px"
          @click="emitConvertSlotProp(slotProps.data)" />

        <PrimeButton
          v-if="showCloseButton"
          v-tooltip="closeButtonToolTip"
          class="mr-1"
          icon="pi pi-check"
          outlined
          rounded
          severity="contrast"
          style="height:40px;width:40px"
          @click="emitCloseSlotProp(slotProps.data)" />

        <PrimeButton
          v-if="showCancelButton"
          v-tooltip="closeButtonToolTip"
          class="mr-1"
          icon="pi pi-times"
          outlined
          rounded
          severity="contrast"
          style="height:40px;width:40px"
          @click="emitCloseSlotProp(slotProps.data)" />

        <PrimeButton
          v-if="showDeleteButton && isAdmin"
          v-tooltip="deleteButtonToolTip"
          icon="pi pi-trash"
          outlined
          rounded
          severity="danger"
          style="height:40px;width:40px"
          @click="emitRowDeleted(slotProps.data)" />
      </template>
    </Column>
    <Column
      v-for="(col,index) of selectedColumns"
      :key="col.field + '_' + index" :field="col.field"
      :header="col.header"
      :sortable="sortable"
      :filterField="col.field"
      :showFilterMenu="false"
      :showFilterMatchModes="false"
      :columnKey="col.field"
      :style="{minWidth: '100px'}"
      style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
      <template #body="{ data, field }">
        <span v-tooltip.top="getToolTip(data,field)"
              style="max-width: 200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis">
          {{ field === 'Created' ? new Date(Number(data[field])).toLocaleDateString()
          : field === 'OpenSince' ? getDueDate(data['Created'])
          : field === 'ExpiresAt' ? new Date(Number(data['ExpiresAt'])).toLocaleDateString()
          : field === 'DeliveryDate' ? new Date(Number(data['DeliveryDate'])).toLocaleDateString()
          : field === 'OfferDate' ? new Date(Number(data['OfferDate'])).toLocaleDateString()
          : field === 'SalesDate' ? new Date(Number(data['SalesDate'])).toLocaleDateString()
          : field === 'timestamp' ? new Date(Number(data['timestamp'])).toLocaleDateString()
          : field === 'Unix' ? new Date(Number(data[field])).toLocaleDateString()
          : field === 'PaymentDateUnix' ? new Date(Number(data['PaymentDateUnix'])).toLocaleDateString()
          : field === 'PaymentDeadlineUnix' ? new Date(Number(data['PaymentDeadlineUnix'])).toLocaleDateString()
          : field === 'Company.Name' ? (data['Company'].hasOwnProperty('CompanyNumber') ? (data['Company'].CompanyNumber
            ? data['Company'].Name + " '" + (data['Company'].City ? data['Company'].City : "") + "' (" + data['Company'].CompanyNumber + ")" : data['Company'].Name) :data['Company'].Name)
          : field === 'Responsible.Name' ? data['Responsible'].Name
          : field === 'Phase.Name' ? data['Phase'].Name
          : field === 'ProjectId' ? getProjectName(data[field])
          : field === 'Members' ? getOfferMembers(data[field])
          : field === 'Status' ? getStatus(data[field])
          : field === "ProjectStatus" ? getProjectStatus(data['Status'])
          : field === 'ProjectCount' ? getProjectCount(data)
          : field === 'OfferCount' ? getOfferCount(data)
          : field === 'RichText' ? stripHTML(data[field])
          : field === 'Notes' ? stripHTML(data[field])
          : field === 'ReportName' ? getVisitReportCreator(data[field])
          : field === "VisitReport" ? getReportName(data[field])
          : field === "PhoneReport" ? getReportName(data[field])
          : field === "Contact.Name" ? typeof data['Contact'] === 'object' &&
            data['Contact'] !== null && Object.prototype.hasOwnProperty.call(data['Contact'], 'Name')
            ? (data['Contact'].Name ?? '-')
            : (data['Contact'] ?? '-')
          : field === "SaleValue" ? formatNumber(data[field])
          : field === "CommissionValue" ? formatNumber(data[field])
          : field === "OfferValue" ? formatNumber(data[field])
          : field === "BonusOption" ? formatNumber(data[field])
          : field === "OrderValue" ? formatNumber(data[field])
          : field === "AmountPaid" ? formatNumber(data[field])
          : field === "MissingAmount" ? formatNumber(data[field])
          : field === "file" ? getFilesName(data[field])
          : field === "Files" ? getFilesName(data[field])
          : field === "text" ? stripHtmlTags(data[field])
          : field === "Description" ? stripHtmlTags(data[field])
          : field === "OptionalParams.companyLocation" ? data['OptionalParams']['companyLocation']
          : field === "Equipment" ? getEquipmentNames(data[field])
          : field === "ReportTableReportType" ? $t(data[field].toLowerCase())
          : field === "Location.LocationName" ? data['Location']?.hasOwnProperty('LocationName') ? (data['Location'].LocationName ?? '-') : (data['Location'] ?? '-')
          : data[field] ?? 'N/A' }}
        </span>
      </template>
      <template v-if="filters" #filter="{ filterModel, filterCallback}">
        <MultiSelect v-if="col.field === 'Company.Name'"
                     v-model="filterModel.value"
                     @change="filterCallback"
                     :options="companyOptions"
                     optionLabel="Name"
                     placeholder="any"
                     filter
                     autoFilterFocus>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <span>{{ slotProps.option.Name }} ({{slotProps.option.CompanyNumber}})</span>
            </div>
          </template>
        </MultiSelect>
        <MultiSelect v-else-if="col.field === 'Responsible.Name'"
                     v-model="filterModel.value"
                     @change="filterCallback"
                     :options="responsibleOptions"
                     optionLabel="Name"
                     placeholder="any"
                     filter
                     autoFilterFocus>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <span>{{ slotProps.option.Name }}</span>
            </div>
          </template>
        </MultiSelect>
        <MultiSelect v-else-if="col.field === 'Phase.Name'"
                     v-model="filterModel.value"
                     @change="filterCallback"
                     :options="projectPhases"
                     optionLabel="Name"
                     placeholder="any"
                     filter
                     autoFilterFocus>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <span>{{ slotProps.option.Name }}</span>
            </div>
          </template>
        </MultiSelect>
      
        <MultiSelect v-else-if="col.field === 'FriendlyName' || col.field === 'Members'"
                     v-model="filterModel.value"
                     @change="filterCallback"
                     :options="memberOptions"
                     optionLabel="Name"
                     placeholder="any"
                     filter
                     autoFilterFocus>
          <template #option="slotProps">
            <div class="flex align-items-center gap-2">
              <span>{{ slotProps.option.Name }}</span>
            </div>
          </template>
        </MultiSelect>
        <InputText v-else-if="col.field === 'Created' || col.field === 'Unix' || col.field === 'ExpiresAt'"
                   v-model="filterModel.value"
                   @change="filterCallback()"
                   dateFormat="dd.mm.yy"
                   placeholder="dd.mm.yyyy" />
        <InputText v-else-if="filterModel"
                   v-model="filterModel.value"
                   @change="filterCallback()"
                   class="p-column-filter" />
      </template>
    </Column>
    <Column v-if="hasReminder || hasAttachments"
            :exportable="false"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis"
            :columnKey="'IconColumn'" >
      <template #body="slotProps">
        <i v-if="hasReminder && remindersExist(slotProps.data)"
           class="pi pi-exclamation-circle mr-2"
           :style="reminderDue(slotProps.data)" />
        <i v-if="attachmentsExist(slotProps.data)" class="pi pi-clone" />
      </template>
    </Column>
    <Column v-if="showTaskIsDue"
            :exportable="false"
            :style="{minWidth: '100px'}"
            style="max-width: 200px; width:200px; overflow-x: hidden;white-space: nowrap;text-overflow: ellipsis"
            :columnKey="'IconColumn'" >
      <template #body="slotProps">
        <i v-if="taskIsDue(slotProps.data)"
          class="pi pi-exclamation-circle text-orange-500 mr-2" />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';
import ToggleButton from 'primevue/togglebutton'
import MultiSelect from "primevue/multiselect";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";
import globalComputedProperties from "@/mixins/global-computed-properties/global-computed-properties";
import * as XLSX from 'xlsx';

export default {
  name: "MaterialTable",
  components:{
    DataTable, Column, InputText, PrimeButton, ToggleButton, MultiSelect, IconField, InputIcon
  },
  mixins:[globalComputedProperties],
  emits: ['rowSelected', 'rowDeleted', 'convertData', 'closeData', 'loadMoreData', 'openDialog'],
  props:{
    dataKey:String,
    dataProp:Array,
    columns:Array,
    // If sorting can be reset after clicking the 3rd time.
    hasRemovableSort:Boolean,
    // "single" or "multiple" acceptable, default is single
    sortMode:String,
    // If the table should have pages, default is false
    hasPaginator:Boolean,
    // The amount of rows allowed on one page
    rowsCount:Number,
    // If the Columns can be resized, default is false,
    resizableColumns:Boolean,
    // If gridlines are shown, default is false,
    showGridlines:Boolean,
    // If the Columns can be sorted, default is false
    sortable:Boolean,
    // If the searchbar at the top is shown. default is false
    hasGlobalFilter:Boolean,
    // With which key the option is shown.
    optionLabel:String,
    // Keys you can search for e.g. Title/ Creator etc.
    filterOptions:Array,
    // If columns can be clicked, "single" and "multiple" are acceptable
    selectionMode:String,
    // All available filters
    filterProp:Object,
    // Boolean if we show the convert, closed or delete button
    showConvertButton:Boolean,
    showCloseButton:Boolean,
    showDeleteButton:Boolean,
    // Boolean if we show the reminder or attachment button + their column
    hasReminder:Boolean,
    hasAttachments:Boolean,
    //String that displays the tooltip for the corresponding button
    closeButtonToolTip:String,
    deleteButtonToolTip:String,
    tableId:String,
    showToggleColumn:Boolean,
    showLoadMoreData:Object,
    showTaskIsDue:Boolean,
    showAddButton:Boolean,
    showCancelButton:Boolean,
  },
  data(){
    return {
      editingRows:[],
      filters: this.filterProp,
      selectedColumns: this.columns,
      selectedOption: null,
      tableKey:0,
      filterToggle:false,
      filterDisplay:null,
      fullscreen:false,
    }
  },
  computed:{
    companyOptions(){
      return this.getCompanyOptions();
    },

    responsibleOptions(){
      return this.getResponsibleOptions();
    },

    contactOptions(){
      return this.getContactOptions();
    },

    memberOptions(){
      return this.getMemberOptions();
    },

    locationOptions(){
      return this.getLocationOptions();
    },

    projectPhases(){
      return this.$store.getters.projectPhases;
    },

    projects(){
      return this.$store.getters.projects;
    },

    offers(){
      return this.$store.getters.offers;
    },

    buttonCount(){
      let counter = 0;
      if(!this.isDesktop)return 0;
      if(this.showConvertButton)counter++;
      if(this.showDeleteButton)counter++;
      if(this.showCloseButton)counter++;
      return counter;
    },

    isAdmin(){
      return this.$store.getters.userType.toLowerCase() === "admin";
    },

    showCancelLoadButton(){
      return this.$store.getters.shouldContinue;
    },
  },
  methods:{
    setFullscreen(){
      this.fullscreen = !this.fullscreen;
    },

    getCompanyOptions(){
      const options = [];

      for(let object of this.dataProp){
        if(object.Company){
          const foundCompany = options.find(company => company.Id === object.Company.Id);
          if(!foundCompany){
            options.push(object.Company);
          }
        }
      }

      return options;
    },

    getLocationOptions(){
      const options = [];
      for(let object of this.dataProp){
        if(object.Location){
          const foundLocation = options.find(location => location.Id === object.Location.Id);
          if(!foundLocation && object.Location.LocationName){
            options.push(object.Location);
          }
        }
      }
 
      return options;
    },

    getResponsibleOptions(){
      const options = [];
      for(let object of this.dataProp){
        if(object.Responsible){
          const foundResponsible = options.find(responsible => responsible.Id === object.Responsible.Id);
          if(!foundResponsible){
            options.push(object.Responsible);
          }
        }
      }
      return options;
    },

    getContactOptions(){
      const options = [];
      for(let object of this.dataProp){
        if(object.Contact){
          const foundContact = options.find(contact => contact.Id === object.Contact.Id);
          if(!foundContact){
            options.push(object.Contact);
          }
        }
      }
      return options;
    },

    getMemberOptions(){
      const options = [];
      for(let object of this.dataProp){
        if(object.Members){
          for(let member of object.Members){
            const foundMember = options.find(currentMember => currentMember.Id === member.Id);
            if(!foundMember){
              options.push(member);
            }
          }
        }
      }
      return options;
    },

    /**
     * return due date of the unix
     * @param unix
     * @returns {number}
     */
    getDueDate(unix){
      const nowUnix = Math.round(new Date().getTime() / 1000);
      const timeStamp = Math.round(unix / 1000);

      return (Math.round((nowUnix - timeStamp) / 60 / 60 / 24))
    },

    toggleFilter(){
      if(this.filterDisplay){
        this.filterDisplay = null;
      }else{
        this.filterDisplay = "row";
      }
    },

    taskIsDue(data){
      return this.getDueDate(data.ExpiresAt) > 0;
    },

    remindersExist(data){
      return data.Reminders.length > 0
    },

    reminderDue(data){
      const reminders = data.Reminders;
      if(reminders.length > 0) {
        if (this.getDueDate(reminders[0].Unix) > 0) {
          return "color:red";
        }
      }
      return "";
    },

    /**
     * Returns the project name.
     * @param projectId
     * @returns {*}
     */
    getProjectName(projectId){
      for(let project of this.projects){
        if(project.Id === projectId){
          return project.Title;
        }
      }
      return "-";
    },

    /**
     * Returns all members from the offer.
     * @param members
     * @returns {string}
     */
    getOfferMembers(members){
      const offerMembers = [];
      for(let member of members){
        offerMembers.push(member.Name)
      }
      return offerMembers.toString();
    },

    getFilesName(files){
      const fileNames = [];
      if(Array.isArray(files) && files.length > 0){
        for(let file of files){
        fileNames.push(file.name);
      }
      }
      return fileNames.toString();
    },

    getStatus(status){
      if(status === "10"){
        return this.$t('open');
      }else{
        return this.$t('closed');
      }
    },

    getProjectStatus(status){
      switch(status){
        case '10':
          return this.$t('open');
        case '20':
          return this.$t('closed');
        case '30':
          return this.$t('cancelled');
        case '40':
          return this.$t('rejected');
        case '50':
          return this.$t('noRealization');
        case '60':
          return this.$t('lost');
        case '70':
          return this.$t('postponed');
        case '80':
          return this.$t('stopped');
        case '90':
          return this.$t('sold');
      }
    },

    /**
     * Shows an icon if the data has either Files key or Attachments
     * and the prop has-attachments is true.
     * @param data
     * @returns {boolean}
     */
    attachmentsExist(data){
      if(data.Files){
        return data.Files.length > 0;
      }else if(data.Attachments){
        return data.Attachments.length > 0;
      }
    },

    getProjectCount(data){
      let count = 0;
      for(let project of this.projects){
        if(project.Company.Id === data.Id) count++;
      }

      return count;
    },

    getOfferCount(data){
      let count = 0;
      for(let offer of this.offers){
        if(offer.Company.Id === data.Id) count++;
      }

      return count;
    },

    stripHTML(html) {
      // Create a dummy div and set its innerHTML to the provided HTML
      let doc = new DOMParser().parseFromString(html, 'text/html');

      // Extract the text content from the dummy div
      return doc.body.textContent || "";
    },

    /**
     *
     * @param visitReport
     * @returns {*}
     */
    getVisitReportCreator(visitReport){
      const creatorSplit = visitReport.split('_')[1];
      return creatorSplit ? creatorSplit.split('.')[0].split('$')[0] : visitReport.split('.')[0];
    },

    getReportName(visitReport){
      const reportNameSplit = visitReport.split('$')[1];
      return reportNameSplit ? reportNameSplit.split('.')[0] : null;
    },

    getToolTip(data,field){
      switch(field){
        case "Created":
        case "ExpiresAt":
        case "OfferDate":
        case "DeliveryDate":
        case "SalesDate":
        case "timestamp":
        case "Unix":
        case "PaymentDateUnix":
        case "PaymentDeadlineUnix":
          return new Date(Number(data[field])).toLocaleDateString();
        case 'OpenSince':
          return this.getDueDate(data['Created']).toString();
        case "Company.Name":
          return (Object.prototype.hasOwnProperty.call(data['Company'], 'CompanyNumber') ? (data['Company'].CompanyNumber ?
              data['Company'].Name + " '" + (data['Company'].City ? data['Company'].City : "") +
              "' (" + data['Company'].CompanyNumber + ")" : data['Company'].Name) :data['Company'].Name)
        case "Responsible.Name":
        return typeof data['Responsible'] === 'object' && data['Responsible'] !== null && Object.prototype.hasOwnProperty.call(data['Responsible'], 'Name')
          ? (data['Responsible'].Name ?? '-')
          : (data['Responsible'] ?? '-');
        case "Phase.Name":
        return typeof data['Phase'] === 'object' && data['Phase'] !== null && Object.prototype.hasOwnProperty.call(data['Phase'], 'Name')
          ? (data['Phase'].Name ?? '-')
          : (data['Phase'] ?? '-');
        case "Contact.Name":
        return typeof data['Contact'] === 'object' && data['Contact'] !== null && Object.prototype.hasOwnProperty.call(data['Contact'], 'Name')
          ? (data['Contact'].Name ?? '-')
          : (data['Contact'] ?? '-');
        case "ProjectId":
          return this.getProjectName(data[field]);
        case "Members":
          return this.getOfferMembers(data[field]);
        case "Status":
          return this.getStatus(data[field]);
        case "ProjectStatus":
          return this.getProjectStatus(data['Status']);
        case "file":
        case "Files":
          return this.getFilesName(data[field]);
        case "OptionalParams.companyLocation":
          return data['OptionalParams']['companyLocation']
        case "Equipment":
          return this.getEquipmentNames(data[field]);
        case "ProjectCount":
          return this.getProjectCount(data);
        case "OfferCount":
          return this.getOfferCount(data);
        case "RichText":
        case "text":
        case "Description":
        case "Notes":
          return this.stripHTML(data[field]);
        case "ReportName":
          return this.getVisitReportCreator(data[field]);
        case "VisitReport":
        case "PhoneReport":
          return this.getReportName(data[field]);
        case "SaleValue":
        case "CommissionValue":
        case "OfferValue":
        case "BonusOption":
        case "OrderValue":
        case "AmountPaid":
        case "MissingAmount":
          return this.formatNumber(data[field]);
        case "ReportTableReportType":
          return this.$t(data[field].toLowerCase()); 
        case "Location.LocationName":
          return data['Location']?.LocationName;
        default:
            return data[field] ?? 'N/A' ;
      }
    },

    getExcelValue(data,field){
      switch(field){
        case "Created":
        case "ExpiresAt":
        case "OfferDate":
        case "DeliveryDate":
        case "SalesDate":
        case "timestamp":
        case "Unix":
        case "PaymentDateUnix":
        case "PaymentDeadlineUnix":
          return new Date(Number(data[field])).toLocaleDateString();
        case 'OpenSince':
          return this.getDueDate(data['Created']);
        case "Company.Name":
          return (Object.prototype.hasOwnProperty.call(data['Company'], 'CompanyNumber') ? (data['Company'].CompanyNumber ?
              data['Company'].Name + " '" + (data['Company'].City ? data['Company'].City : "") +
              "' (" + data['Company'].CompanyNumber + ")" : data['Company'].Name) :data['Company'].Name)
        case "Responsible.Name":
        return typeof data['Responsible'] === 'object' && data['Responsible'] !== null && Object.prototype.hasOwnProperty.call(data['Responsible'], 'Name')
          ? (data['Responsible'].Name ?? '-')
          : (data['Responsible'] ?? '-');
        case "Phase.Name":
        return typeof data['Phase'] === 'object' && data['Phase'] !== null && Object.prototype.hasOwnProperty.call(data['Phase'], 'Name')
          ? (data['Phase'].Name ?? '-')
          : (data['Phase'] ?? '-');
        case "Contact.Name":
        return typeof data['Contact'] === 'object' && data['Contact'] !== null && Object.prototype.hasOwnProperty.call(data['Contact'], 'Name')
          ? (data['Contact'].Name ?? '-')
          : (data['Contact'] ?? '-');
        case "ProjectId":
          return this.getProjectName(data[field]);
        case "Members":
          return this.getOfferMembers(data[field]);
        case "Status":
          return this.getStatus(data[field]);
        case "ProjectStatus":
          return this.getProjectStatus(data['Status']);
        case "file":
        case "Files":
          return this.getFilesName(data[field]);
        case "OptionalParams.companyLocation":
          return data['OptionalParams']['companyLocation']
        case "Equipment":
          return this.getEquipmentNames(data[field]);
        case "ProjectCount":
          return this.getProjectCount(data);
        case "OfferCount":
          return this.getOfferCount(data);
        case "RichText":
        case "text":
        case "Description":
        case "Notes":
          return this.stripHTML(data[field]);
        case "ReportName":
          return this.getVisitReportCreator(data[field]);
        case "VisitReport":
        case "PhoneReport":
          return this.getReportName(data[field]);
        case "SaleValue":
        case "CommissionValue":
        case "OfferValue":
        case "BonusOption":
        case "OrderValue":
        case "AmountPaid":
        case "MissingAmount":
          return this.formatNumber(data[field]);
        case "ReportTableReportType":
          return this.$t(data[field].toLowerCase()); 
        case "Location.LocationName":
          return data['Location']?.LocationName;
        default:
            return data[field] ?? 'N/A' ;
      }
    },

    /**
     * Formats the value of numbers to add a dot,
     * after every 3rd integer, i.e. 1.000, 45.918.347 .
     * @param value
     * @returns {string}
     */
    formatNumber(value) {
      if (value) {
        // Convert value to a string and split into integer and decimal parts
        let parts = value.toString().split(".");

        // Format the integer part with a period as the thousands separator
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");

        // Join the integer and decimal parts back together, if decimal part exists
        return parts.join(",");
      }
      return value;
    },  

    stripHtmlTags(htmlString){
      return htmlString.replace(/<[^>]+>/g, '').replace(/&nbsp;/g, ' ');
    },

    getEquipmentNames(data){
      const equipmentNames = [];

      for(let equipment of data){
        equipmentNames.push(equipment.Name);
      }

      return equipmentNames.toString();
    },

    /**
     * When selecting the columns that are to be shown,
     * we filter through our columns and have to create colFields with the field and header of the column.
     * We check if value array has an entry that includes both of the header and field from colFields.
     * If we don't do it this way, when loading and setting our localStorage selectedColumns,
     * by wanting to add or remove another column it would remove all previously selected columns.
     * Afterwards we set our newly selectedColumns into the localStorage.
     * @param value
     */
    onToggle(value) {
      const selectedColumns = this.columns.filter((col) => {
        const colFields = [col.field, col.header];
        return value.some(val => colFields.includes(val.field) && colFields.includes(val.header));
      });
      this.selectedColumns = selectedColumns;
      if(this.tableId){
        const tablesSettings = JSON.parse(localStorage.getItem('tablesSettings')) || {};
        tablesSettings[this.tableId] = selectedColumns;
        localStorage.setItem('tablesSettings', JSON.stringify(tablesSettings));
      }
    },

    // Load selected columns settings from localStorage
    loadColumnSettings() {
      const tablesSettings = JSON.parse(localStorage.getItem('tablesSettings')) || {};
      const selectedColumns = tablesSettings[this.tableId];
      this.selectedColumns = selectedColumns || this.columns;
    },

    /**
     * Returns true if we have a company Id, aka. a company is selected,
     * false if company is unknown / interested.
     * @param event
     * @returns {*}
     */
    allowToConvert(event){
      return event.data.Company.Id;
    },

    exportToExcel(){
      const filteredData = this.dataProp.map(row => {
        const filteredRow = {};
        this.columns.forEach(column => {
          filteredRow[column.field] = this.getExcelValue(row, column.field);
        });
        return filteredRow;
      });

      // Convert filtered data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(filteredData);

      // Set custom headers
      const headers = this.columns.map(col => col.header);
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: 'A1' });

      // Create a new workbook and add the worksheet
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'DataTable Export');

      // Export the workbook to an Excel file
      XLSX.writeFile(workbook, 'datatable_export.xlsx');
    },

    emitRowSelected(event){
      this.$emit('row-selected',event.data);
    },

    emitRowDeleted(data){
      this.$emit('row-deleted',data);
    },

    emitConvertSlotProp(data){
      this.$emit('convert-data',data);
    },

    emitCloseSlotProp(data){
      this.$emit('close-data',data);
    },

    emitLoadMoreData(){
      this.$emit('load-more-data');
    },

    emitLoadAllData(){
      this.$emit('load-all-data');
    },

    cancelLoadAllData(){
      this.$store.commit('setShouldContinue', false);
    },

    emitOpenDialog(){
      this.$emit('open-dialog');
    },
  },
  mounted(){
    this.loadColumnSettings();
  },
  created(){
    const storedState = JSON.parse(localStorage.getItem(this.tableId));

    if(storedState && storedState.first !== 0){
      storedState.first = 0;
      localStorage.setItem(this.tableId, JSON.stringify(storedState))
    }
  }
}
</script>

<style scoped>

.small-margin-right{
  margin-right:10px;
}

</style>